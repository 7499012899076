import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize, tint } from 'polished';

// Font family
const handleFont = props => {
  if (props.font) {
    return css`
      font-family: '${props.font}', serif;
    `;
  }
};

// Size rules
const handleSize = props => {
  if (props.size === 'xlarge') {
    return css`
      font-size: 2.5rem;
      line-height: 2.5rem;
    `;
  }
  if (props.size === 'large') {
    return css`
      font-size: 1.5rem;
      line-height: 2rem;
    `;
  }

  if (props.size === 'medium') {
    return css`
      font-size: 1.25rem;
      line-height: 1.75rem;
    `;
  }

  if (props.size === 'regular') {
    return css`
      font-size: 1rem;
      line-height: 1.75rem;
    `;
  }
  if (props.size === 'small') {
    return css`
      font-size: 0.875rem;
      line-height: 1.25rem;
    `;
  }
  if(props.size === 'xsmall') {
    return css`
      font-size: 0.75rem;
      line-height: 0.875rem;
    `;
  }
};

// Weight rules
const handleWeight = props => {
  if (props.weight === 'light') {
    return css`
      font-weight: 300;
      
      strong {
        font-weight: 500 !important;
      }
    `;
  }
  if (props.weight === 'normal') {
    return css`
      font-weight: 400;
    `;
  }
  if (props.weight === 'medium') {
    return css`
      font-weight: 500;
    `;
  }
  if (props.weight === 'bold') {
    return css`
      font-weight: 700;
    `;
  }
  if (props.weight === 'black') {
    return css`
      font-weight: 800;
    `;
  }
};

// Color rules
const handleColor = props => {
  if (props.opacity) {
    return css`
      color: ${props.color
        ? transparentize(1 - props.opacity, props.theme.colors[props.color] ? props.theme.colors[props.color] : props.color)
        : transparentize(1 - props.opacity, props.theme.colors.ceBlack)};
    `;
  } else if (props.tint) {
    return css`
      color: ${props.color
        ? tint(1 - props.tint, props.theme.colors[props.color] ? props.theme.colors[props.color] : props.color)
        : tint(1 - props.tint, props.theme.colors.ceBlack)};
    `;
  } else {
    return css`
      color: ${props.color
        ? props.theme.colors[props.color] ? props.theme.colors[props.color] : props.color
        : props.theme.colors.ceBlack};
    `;
  }
};

// Styled component
const TextElement = styled.p`
  ${handleFont};
  ${handleSize};
  ${handleWeight};
  ${handleColor};
  transition: all 0.25s ease-in-out;
  white-space: pre-line;

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props =>
    props.uppercase
      ? css`
          text-transform: uppercase;
        `
      : null}

  ${props =>
    props.italic
      ? css`
          font-style: italic;
        `
      : null}

  ${props =>
    props.truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  ${props => props.letterSpacing && css`
    letter-spacing: ${props.letterSpacing};
  `}

  strong {
    font-weight: 700;
  }
`;

// Component
const Text = props => {
  const { type, children } = props;

  return (
    <TextElement as={type.toString()} {...props}>
      {children}
    </TextElement>
  );
};

Text.propTypes = {
  type: PropTypes.oneOf(['p', 'span', 'label']),
  size: PropTypes.oneOf(['xlarge', 'large', 'medium', 'regular', 'small', 'xsmall']),
  weight: PropTypes.oneOf(['light', 'normal', 'medium', 'bold', 'black']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.string,
  tint: PropTypes.number,
  opacity: PropTypes.number,
  uppercase: PropTypes.bool,
  italic: PropTypes.bool,
  truncate: PropTypes.bool,
  underline: PropTypes.bool,
  font: PropTypes.string,
  letterSpacing: PropTypes.string
};

Text.defaultProps = {
  type: 'p',
  size: 'regular',
  weight: 'normal',
  color: null,
  tint: 1,
  opacity: null,
  uppercase: false,
  italic: false,
  align: 'left',
  truncate: false,
  underline: false,
  font: null,
  letterSpacing: null
};

export default Text;
