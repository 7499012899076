import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  
  * {
    box-sizing: border-box;
  }

  *,
  body {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a {
    text-decoration: none;
    display: inline-block;
  }
  
  .ReactModal__Body--open {
    overflow: hidden;
  }

  main {
    overflow: hidden;
  }
`

export default GlobalStyle
