import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby";

const Button = ({ to, link, target, label, color, size }) => {
  if(to) {
    return (
      <Link to={to}>
        <ButtonElement color={color} size={size}>{label}</ButtonElement>
      </Link>
    )
  }
  return (
    <a href={link} target={target}>
      <ButtonElement color={color} size={size}>{label}</ButtonElement>
    </a>
  )
}

export default Button

const ButtonElement = styled.div`
  display: inline-block;
  appearance: none;
  border: solid 1px transparent;
  background-color: ${({theme, color}) => theme.colors[color] || color};
  border-radius: ${({theme}) => theme.radius.small};
  color: ${({theme}) => theme.colors.ceWhite};
  min-height: ${({size}) => size === 'small' ? '1.5rem' : '2.5rem'};
  font-size: ${({size}) => size === 'small' ? '0.75rem' : '0.875rem'};
  line-height: ${({size}) => size === 'small' ? '0.75rem' : '0.875rem'};
  text-align: center;
  padding: ${({size}) => size === 'small' ? '0.375rem' : '0.8125rem 1rem'};
  font-weight: 600;
  position: relative;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: ${({theme}) => theme.colors.ceWhite};
    background-color: ${({theme, color}) => theme.colors.ceBlack};
    text-decoration: none;
  }
`;
