import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from '../../theme'

import Footer from './Footer'

const Layout= ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
      datoCmsFooter {
        contactLabel
        contactEmail
        socials {
          id
          name
          url
          icon {
            url
            alt
            gatsbyImageData(
              width: 18
              placeholder: NONE
            )
          }
        }
      }
    }
  `);


  return (
    <>
      <Helmet>
        <style>
          @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
        </style>
        <link href="/assets/fonts.css" rel="stylesheet" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
        <main>{children}</main>
        <Footer content={data.datoCmsFooter} />
      </ThemeProvider>
    </>
  )
}

export default Layout
