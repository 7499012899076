import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";
import { Grid, Row, Col} from 'react-styled-flexboxgrid'

import { Wrapper, Text } from '../../atoms'

const Footer = ({ content }) => {
  return(
    <footer>
      <Wrapper pv={'xxxlarge'}>
        <Grid>
          <Row>
            <Col xs={4}>
              <FooterLink href={`mailto:${content.contactEmail}`} rel={'noreferrer noopener'}>
                <Text letterSpacing={'0.3rem'} uppercase size={'xsmall'} weight={'bold'}>{content.contactLabel}</Text>
              </FooterLink>
            </Col>
            <Col xs={4}>
              {content.socials && content.socials.length >0 && (
                <Wrapper dFlex justifyContent={'center'} alignItems={'center'}>
                  {content.socials.map(social => (
                    <Wrapper key={social.id} flex={'0 0 auto'} mh={'small'}>
                      <a href={social.url} rel={'noreferrer noopener'}>
                        <GatsbyImage image={social.icon.gatsbyImageData} alt={social.icon.alt || social.name} draggable={false}/>
                      </a>
                    </Wrapper>
                  ))}
                </Wrapper>
              )}
            </Col>
            <Col xs={4}>
              <Text opacity={0.5} align={'right'} letterSpacing={'0.3rem'} uppercase size={'xsmall'} weight={'bold'}>Mentions légales</Text>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    </footer>
  )
}

export default Footer

const FooterLink = styled.a`
  opacity: 0.5;
  transition: all 0.25s ease-in-out;
  
  &:hover {
    opacity: 1;
  }
`;
