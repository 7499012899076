import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ content, lang, meta }) {
  // Query
  const { datoCmsSite } = useStaticQuery(
    graphql`
      query {
        datoCmsSite {
          globalSeo {
            facebookPageUrl
            fallbackSeo {
              description
              title
              twitterCard
            }
            twitterAccount
            siteName
          }
        }
      }
    `
  );

  const metaTitle =
    (content && content.title) || datoCmsSite.globalSeo.fallbackSeo.title;
  const metaDescription =
    (content && content.description) ||
    datoCmsSite.globalSeo.fallbackSeo.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${datoCmsSite.globalSeo.siteName}`}
      meta={[
        {
          name: `application-name`,
          content: `Reveal Studio`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: datoCmsSite.globalSeo.fallbackSeo.twitterCard,
        },
        {
          name: `twitter:creator`,
          content: datoCmsSite.globalSeo.twitterAccount,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
