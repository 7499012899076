const theme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 2, // rem
    outerMargin: 1, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 48.75, // rem
      md: 64, // rem
      lg: 73.75, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48.75, // em
      md: 64, // em
      lg: 80, // em
    },
  },
  colors: {
    ceBlue:        '#85BDE7',
    ceBlack:       '#22262B',
    ceDarkblue:    '#133856',
    ceDarkenblue:  '#07151F',
    ceWhite:       '#FFFFFF',
    ceDarkblack:   '#110D0D',
    ceRed:         '#D0021B',
    ceLightBlue:   '#f3f8fc',
    ceLightGrey:   '#f4f4f4'
  },
  spacing: {
    xxsmall: '0.125rem',
    xsmall: '0.25rem',
    smaller: '0.5rem',
    small: '0.75rem',
    regular: '1rem',
    medium: '1.5rem',
    large: '2rem',
    xlarge: '2.5rem',
    xxlarge: '3rem',
    xxxlarge: '3.5rem',
    huge: '5rem',
    xhuge: '6rem',
    xxhuge: '7.75rem',
    xxxhuge: '10rem',
    auto: 'auto',
  },
  radius: {
    small: '.25rem',
    medium: '.5rem',
    big: '1rem'
  },
}

export default theme
