import React, { useMemo } from 'react';

const Video = ({ video_src, video, color }) => {

  const manageVideoSrc = () => {
    if(video.provider === 'facebook') {
      return `https://www.facebook.com/plugins/video.php?height=${video.height}&href=${video.url}&show_text=false&width=${video.width}&t=0`
    }
    if(video.provider === 'vimeo') {
      const ids = video.url.replace('https://vimeo.com/', '').split('/')
      if(ids.length > 1) {
        return `https://player.vimeo.com/video/${ids[0]}?h=${ids[1]}&color=${color}&title=0&byline=0&portrait=0`
      } else {
        return video_src
      }
    }
    return ''
  }

  const getVideoSrc = useMemo(() => manageVideoSrc, [video])

  return (
    <div>
      {video && video.provider === 'facebook' && (
        <>
          <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
            <iframe
              title={'Video'}
              src={getVideoSrc()}
              style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
              frameBorder="0"
              allowFullScreen />
          </div>
          </>
      )}
      {(video && video.provider === 'vimeo') && (
        <>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe title={'Video'} src={getVideoSrc()} style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} frameBorder="0"  allowFullScreen />
          </div>
        </>
      )}
      {!video && video_src && (
        <>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe title={'Video'} src={video_src} style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} frameBorder="0"  allowFullScreen />
          </div>
        </>
      )}
    </div>
  )
};

export default Video;
