import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { tint, transparentize } from 'polished';

const handleDisplay = props => {
  if (props.dFlex || props.dInlineFlex) {
    return css`
      display: ${props.dFlex ? 'flex' : 'inline-flex'};
      flex-wrap: ${props.flexWrap};
      flex-direction: ${props.flexDirection};
      justify-content: ${props.justifyContent};
      align-items: ${props.alignItems};
      align-content: ${props.alignContent};
    `;
  }
};

const handleSize = props => {
  return css`
    flex-basis: ${props.flexBasis};
    flex: ${props.flex};
  `;
};

const handlePadding = props => {
  if (props.p) {
    return css`
      padding: ${props => props.theme.spacing[props.p]};
    `;
  } else {
    return css`
      padding-left: ${props =>
        props.ph
          ? props.theme.spacing[props.ph]
          : props.pl
          ? props.theme.spacing[props.pl]
          : 0};
      padding-right: ${props =>
        props.ph
          ? props.theme.spacing[props.ph]
          : props.pr
          ? props.theme.spacing[props.pr]
          : 0};
      padding-top: ${props =>
        props.pv
          ? props.theme.spacing[props.pv]
          : props.pt
          ? props.theme.spacing[props.pt]
          : 0};
      padding-bottom: ${props =>
        props.pv
          ? props.theme.spacing[props.pv]
          : props.pb
          ? props.theme.spacing[props.pb]
          : 0};
    `;
  }
};

const handleMargin = props => {
  if (props.m) {
    return css`
      margin: ${props => props.theme.spacing[props.m]};
    `;
  } else {
    return css`
      margin-left: ${props =>
        props.mh
          ? props.theme.spacing[props.mh]
          : props.ml
          ? props.theme.spacing[props.ml]
          : 0};
      margin-right: ${props =>
        props.mh
          ? props.theme.spacing[props.mh]
          : props.mr
          ? props.theme.spacing[props.mr]
          : 0};
      margin-top: ${props =>
        props.mv
          ? props.theme.spacing[props.mv]
          : props.mt
          ? props.theme.spacing[props.mt]
          : 0};
      margin-bottom: ${props =>
        props.mv
          ? props.theme.spacing[props.mv]
          : props.mb
          ? props.theme.spacing[props.mb]
          : 0};
    `;
  }
};

const handleRadius = props => {
  if (props.r) {
    return css`
      border-radius: ${props => props.theme.radius[props.r]};
    `;
  }
};

const handleColor = props => {
  if (props.color) {
    if (props.tint) {
      return css`
        background-color: ${props =>
          tint(1 - props.tint, props.theme.colors[props.color] || props.color)};
      `;
    }
    if (props.transparentize) {
      return css`
        background-color: ${props =>
          transparentize(
            1 - props.transparentize,
            props.theme.colors[props.color] || props.color
          )};
      `;
    }
    return css`
      background-color: ${props => props.theme.colors[props.color] || props.color};
    `;
  }
};

const handleBorder = props => {
  if (props.border) {
    return css`
      border: ${({
        theme,
        borderWidth,
        borderStyle,
        borderColor,
        borderTint,
      }) =>
        `${borderWidth}px ${borderStyle} ${tint(
          1 - borderTint,
          theme.colors[borderColor]
        )}`};
    `;
  }
};

const Wrapper = styled.div`
  position: relative;
  ${handleDisplay};
  ${handleSize};
  ${handlePadding};
  ${handleMargin};
  ${handleRadius};
  ${handleColor};
  ${handleBorder};
  transition: all 0.25s ease-in-out;
  transition-property: background, border, box-shadow, opacity;
`;

Wrapper.propTypes = {
  dFlex: PropTypes.bool,
  dInlineFlex: PropTypes.bool,
  flexWrap: PropTypes.string,
  flexDirection: PropTypes.string,
  flexBasis: PropTypes.string,
  flex: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  alignContent: PropTypes.string,
  m: PropTypes.string,
  mh: PropTypes.string,
  mv: PropTypes.string,
  mt: PropTypes.string,
  mr: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
  p: PropTypes.string,
  ph: PropTypes.string,
  pv: PropTypes.string,
  pt: PropTypes.string,
  pr: PropTypes.string,
  pb: PropTypes.string,
  pl: PropTypes.string,
  r: PropTypes.string,
  color: PropTypes.string,
  tint: PropTypes.number,
  transparentize: PropTypes.number,
  border: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  borderTint: PropTypes.number,
  borderStyle: PropTypes.string,
};

Wrapper.defaultProps = {
  dFlex: false,
  dInlineFlex: false,
  flexWrap: 'row',
  flexDirection: 'row',
  jusitifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  flexBasis: 'auto',
  flex: '0 0 auto',
  m: null,
  mh: null,
  mv: null,
  mt: null,
  mr: null,
  mb: null,
  ml: null,
  p: null,
  ph: null,
  pv: null,
  pt: null,
  pr: null,
  pb: null,
  pl: null,
  r: null,
  color: null,
  tint: null,
  transparentize: null,
  border: null,
  borderWidth: 1,
  borderColor: 'ceDarkblue',
  borderTint: 1,
  borderStyle: 'solid',
};

export default Wrapper;
